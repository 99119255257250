import React, { Component } from 'react'
import './App.css'
import axios from 'axios'

import Sun from './images/sun.jpg'
import Rain from './images/rain.jpg'

class App extends Component {

   constructor() {
      super()
      this.state = {
         // api_key: 'hPwp6La85vM9s3E7gLbOmG6o4BCCPmWR',
         // api_key: 'Nb09ZBY9VgLWEoFmYxljoyx3tfAX0Bsn',
         api_key: 'QMbb29YZV2YAuOwpEHKbDp7n1EYkRunO',
         key: '',
         weather: 'sun', // rain || sun
         test: false,
      }
   }
   count = 0

   componentDidMount() {
      this.onStart()
      document.addEventListener('contextmenu', event => event.preventDefault());
   }

   onStart = async() => {
      let {api_key} = this.state
      // let panel = this.getParameterByName('panel') || 2264
      let panel = this.getParameterByName('panel')
      let fallback = this.getParameterByName('fallback')
      if(panels[panel] != undefined && !fallback ){
         this.setState({ key: panels[panel] })
      }else{
         console.log('using location key api')
         let key_res = await axios.get(`http://tribal.wunderfauks.com/wp/wp-json/tribal/get_location?panel=${panel}`)
         let key = key_res.data
         this.setState({key})
      }
      setTimeout( () => {
         this.refresh()
      }, 50)
      setInterval( ()=> {
         this.refresh()
         this.count++
         console.log(this.count)
         if(this.count > 96) {
            this.count = 0
            window.location.reload()
         }
      }, 15 * 60 * 1000)

   }

   refresh = async() => {
      const {key, api_key} = this.state
      console.log(key)
      let panel = this.getParameterByName('panel') || 2264
      try{
         let res = await axios.get(`http://dataservice.accuweather.com/currentconditions/v1/${key}?apikey=${api_key}`)
         let {data} = res
         if(data.length < 1) {
            alert('Weather Not Available')
            return
         }
         console.log(data[0].Link)
         let icon = data[0].WeatherIcon
         let weather = ''
         switch(icon){
            case 8: case 12: case 13: case 14: case 15: case 16: case 17: case 18: case 19: case 20: case 21:
            case 22: case 23: case 24: case 25: case 26: case 29: case 39: case 40: case 41: case 42: case 43:
            case 44:
            weather = 'rain'; break;
            default: weather = 'sun'
         }
         console.log(data[0].WeatherText)
         this.setState({ weather, acc_link: data[0].Link,  })
         // await axios.post(`http://tribal.wunderfauks.com/wp/wp-json/tribal/test`, { key, weather, title: data[0].Link + ' ' + panel })

      }
      catch(e) {
         console.log('location key error')
         console.log(e.response)
         await axios.post(`http://tribal.wunderfauks.com/wp/wp-json/tribal/error`, {
            type: 'key',
            panel,
            message: JSON.stringify(e.response)
         })
         setTimeout( () => {
            let fallback = this.getParameterByName('fallback')
            if(!fallback) {
               window.location.href = window.location.href + '&fallback=1'
            }
         }, 300)
         // window.location.reload()
      }

   }

   render() {
      const {weather, test, acc_link} = this.state

      return (
         <div className="App">
            {
               weather === 'rain'
               ?
               <img src={Rain} width="100%" alt="" />
               :
               <img src={Sun} width="100%" alt="" />
            }
            {test &&
               <div id="test">
                  <a href={acc_link} target="_blank">{acc_link}</a>
               </div>
            }

         </div>
      );
   }

   getParameterByName = (name) => {
      let url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
   }
}

export default App;

const panels = {
   2264: 1605012,
   5441: 1603345,
   5202: 1605012,
   5358: 1605012,
   5173: 1605012,
   2437: 1569680,
   5444: 1605012,
   5443: 1603345,
   5491: 1605012,
   5134: 1603503,
   5137: 1605012,
   6031: 1605002,
   2016: 1605085,
   2040: 1605085,
   5460: 1569856,
   5440: 1603345,
   2041: 1603345,
   3384: 1569845,
   3389: 1603556,
   7229: 1569852,
   6056: 1569834,
   7045: 1569836,
   6425: 1569836,
   8315: 1569852,
   5546: 1604992,
   5248: 1603345,
   2234: 1605012,
   5169: 1569839,
   4396: 1569720,
   5406: 1604992,
   2467: 1603554,
   2258: 1605012,
   5414: 1604992,
   6375: 1569769,
   5058: 1569836,
   4089: 1569836,
   2315: 1603554,
   2333: 1569802,
   1525: 1604184,
   2072: 1605009,
   5193: 1604967,
   2474: 1605009,
   5317: 1604972,
   5403: 1604961,
   5175: 1605012,
   5355: 1605012,
   3395: 1603556,
   5457: 1569856,
   5149: 1605012,
   6357: 1603503,
   5452: 300549,
   6227: 1569833,
   2038: 1605085,
   6226: 1569833,
   5018: 1603556,
   2085: 1605079,
   2017: 1605085,
   3261: 1604450,
   5118: 1569726,
   5110: 1569856,
   5250: 1603345,
   2501: 1605009,
   1089: 1605079,
   5130: 1569861,
   5146: 1604992,
   2034: 1605009,
   5148: 1603345,
   2432: 1605009,
   5524: 1569840,
   2280: 1603345,
   2274: 1603345,
   5107: 1569856,
   2035: 1603345,
   5543: 1605012,
   5394: 1603503,
   6150: 1569833,
   3463: 1569660,
   7241: 1569852,
   5028: 300576,
   7084: 1569661,
   1494: 1604184,
   3001: 1603554,
   2037: 1603345,
   3421: 1604450,
   3066: 1603343,
   6402: 1569834,
   7118: 1569661,
   4383: 1569720,
   5017: 1603556,
   2108: 1605079,
   3380: 1605568,
   2256: 1605079,
   1099: 1569837,
   3052: 1603343,
   5271: 1569834,
   6392: 1569858,
   5270: 1569717,
   3341: 300572,
   2402: 1605079,
   3509: 300582,
   1275: 1604184,
   2388: 1605009,
   2070: 1605009,
   4193: 1569842,
   2472: 1605009,
   5236: 1604993,
   2082: 1605009,
   5430: 1604992,
   5061: 1569836,
   3410: 1569772,
   5258: 1569671,
   2134: 1605012,
   2236: 1605009,
   2267: 1605009,
   3289: 1569699,
   2270: 1605085,
   2273: 1605009,
   1002: 1605012,
   5498: 1569727,
   5495: 1605009,
   6387: 1569769,
   5114: 1605009,
   2340: 1569822,
   2081: 1605009,
   5429: 1604992,
   1004: 1605012,
   5408: 1604992,
   9036: 1605085,
   5555: 1603345,
   3423: 1605000,
   5339: 1603345,
   1003: 1605012,
   2260: 1605012,
   2429: 1605012,
   2470: 1605009,
   2268: 1605009,
   5393: 1605012,
   2291: 1605009,
   5418: 1604992,
   5485: 1604992,
   6086: 1569834,
   3407: 1569772,
   5131: 1569856,
   7098: 1569661,
   6085: 1569834,
   2466: 1603554,
   4217: 1569842,
   5203: 1605012,
   2471: 1605009,
   3030: 1569685,
   2276: 1605009,
   2239: 1605009,
   2235: 1605009,

}
